import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const PageApp = lazy(() => import('src/pages/dashboard/app'));
const PageAdmin = lazy(() => import('src/pages/dashboard/admin'));
const PageWebhooks = lazy(() => import('src/pages/dashboard/webhooks'));
const PageAuth = lazy(() => import('src/pages/dashboard/auth'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <PageApp />, index: true },
      { path: 'admin', element: <PageAdmin /> },
      { path: 'webhooks', element: <PageWebhooks /> },
      { path: 'auth', element: <PageAuth /> },
    ],
  },
];
