// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    app: `${ROOTS.DASHBOARD}/app`,
    admin: `${ROOTS.DASHBOARD}/admin`,
    webhooks: `${ROOTS.DASHBOARD}/webhooks`,
    auth: `${ROOTS.DASHBOARD}/auth`,
  },
};
