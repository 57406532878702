import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ disabledLink = false, full = false, color = '#068465', sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src="/logo/logo_single.svg"
        sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
      />
    );
    const logoFull = (
      <Box
        component="img"
        src="/logo/logo_full.svg"
        sx={{ height: 40, cursor: 'pointer', ...sx }}
      />
    );

    // const logo = (
    //   <Box
    //     ref={ref}
    //     component="div"
    //     sx={{
    //       width: 40,
    //       height: 40,
    //       display: 'inline-flex',
    //       ...sx,
    //     }}
    //     {...other}
    //   >
    //     <svg
    //       version="1.2"
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 300 300"
    //       width="300"
    //       height="300"
    //     >
    //       <title>logotipo-svg</title>

    //       <g id="g848">
    //         <path
    //           id="path18"
    //           fill={color}
    //           d="m259.3 171.6c-1.2-7.4-2.5-14.8-3.9-22.2q-3.6-19.8-7.5-39.5c-2.7-13.5-5.5-26.9-8-40.4-2.3-11.7-5.6-22.9-12-33-12.5-19.7-30.5-30.1-53.7-31.4q-3.5-0.2-7 0-3.5 0.3-6.9 0.9-3.4 0.7-6.8 1.7-3.3 1-6.5 2.5c-15.6 6.6-27.4 17.7-36.6 31.7-16.6 25.1-33 50.4-49.8 75.3-12 17.8-19.8 36.9-21.3 58.4q-0.3 4.4-0.3 8.8 0 4.4 0.4 8.8 0.4 4.4 1.1 8.8 0.8 4.3 1.8 8.6 1.7 6.6 4.2 12.9 2.5 6.4 5.7 12.4 3.2 6 7.1 11.5 3.9 5.6 8.4 10.7 1.9 2.1 3.9 4.1 1.9 2 4 3.9 2.1 1.8 4.3 3.6 2.1 1.8 4.4 3.5 0.5 0.3 0.9 0.7 0.5 0.3 1 0.7 0.4 0.3 0.9 0.6 0.5 0.4 1 0.7v-148.5c25.5 0 31.5 14.7 31.5 14.7l1.7-1.6q3.2-3.2 7-5.8 3.8-2.5 7.9-4.4 4.2-1.9 8.6-3 4.4-1.1 9-1.4c24.4-1.5 42.5 8.9 55.4 29.2q3.2 5.1 5.4 10.8 2.3 5.6 3.5 11.5 1.3 5.9 1.5 12 0.2 6-0.7 12-0.5 5.7-2 11.2-1.5 5.5-3.9 10.7-2.4 5.2-5.7 9.9-3.2 4.7-7.2 8.8c-2.7 2.9-5.7 5.5-8.9 7.7-3.3 2.3-6.8 4.1-10.4 5.7-3.7 1.5-7.5 2.6-11.4 3.3-3.9 0.7-7.8 1-11.8 0.8q-4.3 0-8.6-0.8-4.4-0.7-8.5-2.2-4.1-1.5-7.9-3.6-3.8-2.2-7.2-5c-1.4-1-2.6-2.2-4.2-3.5v46.4q2.7 0.8 5.5 1.4 2.7 0.6 5.5 1.2 2.8 0.5 5.6 0.8 2.8 0.4 5.6 0.7 4.2 0.2 8.5 0.1 4.2-0.1 8.4-0.5 4.3-0.4 8.4-1.2 4.2-0.8 8.3-1.9c9.2-2.5 18-6.1 26.3-10.8 8.2-4.7 15.8-10.4 22.6-17 6.9-6.7 12.8-14.1 17.7-22.2 4.9-8.2 8.8-16.9 11.5-26q0.9-2.9 1.6-5.8 0.7-2.9 1.2-5.8 0.6-2.9 1-5.9 0.4-3 0.6-6 0.3-2.5 0.4-5 0.1-2.6 0.1-5.1 0-2.6-0.2-5.1-0.2-2.5-0.5-5.1z"
    //         />
    //         <path
    //           id="path20"
    //           fill={color}
    //           d="m123.6 178.6c0.9-2.6 2.1-5.1 3.6-7.5 1.5-2.3 3.3-4.4 5.3-6.3 2.1-1.9 4.3-3.5 6.8-4.8 2.4-1.3 5-2.3 7.7-2.9q3.3-0.9 6.8-1 3.4-0.1 6.7 0.7 3.4 0.7 6.5 2.2 3.1 1.5 5.7 3.6 3.2 2.4 5.8 5.5 2.5 3.1 4.4 6.6 1.8 3.6 2.8 7.4 1 3.9 1.1 7.9v2.5q0 3.4-0.5 6.9-0.6 3.4-1.8 6.7-1.2 3.3-2.9 6.3-1.8 3-4.1 5.6-2.2 2.7-4.9 4.7-2.8 2.1-5.9 3.5-3.1 1.4-6.5 2.1-3.4 0.6-6.9 0.5c-2.7-0.1-5.4-0.5-8-1.3-2.7-0.8-5.2-1.9-7.5-3.4-2.3-1.4-4.4-3.2-6.3-5.2-1.9-2-3.5-4.2-4.7-6.6q-2.4-3.8-3.8-7.9-1.4-4.2-1.8-8.6-0.4-4.4 0.2-8.7 0.6-4.4 2.2-8.5z"
    //         />
    //       </g>
    //     </svg>
    //   </Box>
    // );

    if (disabledLink && full) {
      return logoFull;
    }

    if (disabledLink) {
      return logo;
    }

    if (full) {
      return (
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
          {logoFull}
        </Link>
      );
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  full: PropTypes.bool,
  color: PropTypes.string,
  sx: PropTypes.object,
};

export default Logo;
